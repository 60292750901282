import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { SidebarWrapper } from 'components';

import { isMedia, isMTI } from './helpers';

import Login from 'routes/Login';
import Splash from 'routes/Splash';
import Forgot from 'routes/Forgot';
import Reset from 'routes/Reset';
import MaintenanceSplash from 'routes/MaintenanceSplash';
import AdminAdmins from 'routes/AdminRoutes/Admins';
import AdminShows from 'routes/AdminRoutes/Shows';
import AdminOrganizations from 'routes/AdminRoutes/Organizations';
import AdminUsers from 'routes/AdminRoutes/Users';
import AdminAccount from 'routes/AdminRoutes/Account';
import AdminQuotes from 'routes/AdminRoutes/Quotes';
import AdminReporting from 'routes/AdminRoutes/Reporting';
import AdminEmails from 'routes/AdminRoutes/Emails';
import AdminEdits from 'routes/AdminRoutes/Edits';
import AdminEdit from 'routes/AdminRoutes/Edit';
import OrgShows from 'routes/OrgRoutes/Shows';
import OrgAccount from 'routes/OrgRoutes/Account';
import OrgContacts from 'routes/OrgRoutes/Contacts';
import OrgEdits from 'routes/OrgRoutes/Edits';
import OrgEdit from 'routes/OrgRoutes/Edit';
import RehearsalMain from 'routes/RehearsalAccess/RehearsalMain.js';
import RehearsalDetail from 'routes/RehearsalAccess/RehearsalDetail.js';
import FAQ from 'routes/FAQ';
import MacApp from 'routes/MacApp';
import Privacy from 'routes/Privacy';
//import { identifier } from '@babel/types';

const AuthenticatedRoute = props => {
  const type = window.localStorage.getItem('type');
  const portal = type && type.includes('admin') ? 'admin' : 'org';
  const tempPassword = window.localStorage.getItem('tempPassword');

  const [windowSize, setWindowSize] = useState(isMedia());
  const maintenance = process.env.REACT_APP_SITE_MAINTENANCE;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(isMedia());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (maintenance !== 'TRUE') {
    if (props.secure) {
      if (props.path.includes('/maintenance')) {
        return <Redirect to="/" />;
      }
      const authToken = window.localStorage.getItem('token');
      if (!authToken && !props.path.includes(`/reset`)) {
        return <Redirect to="/" />;
      }

      return windowSize === 'desktop' ? (
        <SidebarWrapper type={portal}>
          {tempPassword && props.path !== `/${portal}/account` ? (
            <Redirect to={`/${portal}/account`} />
          ) : (
            <Route {...props} />
          )}
        </SidebarWrapper>
      ) : (
        <>
          <SidebarWrapper type={portal} />
          {tempPassword && props.path !== `/${portal}/account` ? (
            <Redirect to={`/${portal}/account`} />
          ) : (
            <Route {...props} />
          )}
        </>
      );
    } else {
      if (props.path.includes('/maintenance')) {
        return <Redirect to="/" />;
      }
      if (
        window.localStorage.getItem('token') &&
        !props.path.includes(`/reset`)
      ) {
        let path = `${portal}/shows`;
        return <Redirect to={path} />;
      }

      return <Route {...props} />;
    }
  } else if (maintenance) {
    if (props.path.includes('maintenance')) {
      return <Route {...props} />;
    } else {
      return <Redirect to={'/maintenance'} />;
    }
  }
};

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute exact path="/" component={isMTI ? Splash : Login} />
        <AuthenticatedRoute exact path="/production" component={Login} />
        <AuthenticatedRoute exact path="/forgot" component={Forgot} />
        <AuthenticatedRoute exact path={`/reset/:id`} component={Reset} />
        <Redirect exact from="/reset" to="/" />
        <AuthenticatedRoute
          exact
          path={`/maintenance`}
          component={MaintenanceSplash}
        />

        {/* Admin routes */}
        <AuthenticatedRoute
          secure
          path="/admin/admins"
          component={AdminAdmins}
        />
        <AuthenticatedRoute secure path="/admin/shows" component={AdminShows} />
        <AuthenticatedRoute
          secure
          path="/admin/organizations"
          component={AdminOrganizations}
        />
        <AuthenticatedRoute secure path="/admin/users" component={AdminUsers} />
        <AuthenticatedRoute
          secure
          path="/admin/account"
          component={AdminAccount}
        />
        <AuthenticatedRoute
          secure
          path="/admin/quotes"
          component={AdminQuotes}
        />
        <AuthenticatedRoute
          secure
          path="/admin/reporting"
          component={AdminReporting}
        />
        <AuthenticatedRoute
          secure
          path="/admin/emails"
          component={AdminEmails}
        />
        <AuthenticatedRoute secure path="/admin/edits/:id" component={AdminEdit} />
        <AuthenticatedRoute secure path="/admin/edits" component={AdminEdits} />
        {/* Org Routes */}
        <AuthenticatedRoute secure path="/org/shows" component={OrgShows} />
        <AuthenticatedRoute secure path="/org/account" component={OrgAccount} />
        <AuthenticatedRoute secure path="/org/contacts" component={OrgContacts} />

        <AuthenticatedRoute secure path="/org/edits/:id" component={OrgEdit} />
        <AuthenticatedRoute secure path="/org/edits" component={OrgEdits} />

        <Route exact path="/rehearsal/:id?" component={RehearsalMain} />
        <Route exact path="/rehearsal/stream/:id" component={RehearsalDetail} />
        {isMTI && <Route exact path="/faq" component={FAQ} />}
        {!isMTI && <Redirect exact from="/faq" to="/" />}
        {!isMTI && <Route exact path="/privacy-policy" component={Privacy} />}
        {isMTI && <Redirect exact from="/privacy-policy" to="/" />}
        <Route exact path="/macapp" component={MacApp} />
      </Switch>
    </BrowserRouter>
  );
};
