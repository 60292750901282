import { http, getMe } from 'data-graphql';
import styled from 'styled-components';
import React from 'react';
import { Input, Button, Modal } from 'components';
import { toast } from 'react-toastify';
import { UserEditRequest } from 'components';

const pendingStatuses = ['pending', 'submitted'];

const Payment = styled.div`
  .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 12px 8px;
  }

  label {
    position: relative;
    color: black;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
  }

  .group label:not(:last-child) {
    border-bottom: 1px solid #f0f5fa;
  }

  label > span {
    width: 115px;
    text-align: right;
    margin-right: 30px;
  }

  .field {
    background: transparent;
    font-weight: 300;
    color: #31325f;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
  }

  .field::-webkit-input-placeholder {
    color: #cfd7e0;
  }

  .field::-moz-placeholder {
    color: #cfd7e0;
  }

  button {
    float: left;
    display: block;
    background: #555abf;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
    cursor: pointer;
  }

  button:focus {
    background: #555abf;
  }

  button:active {
    background: #43458b;
  }

  button[disabled] {
    background: #8898aa;
    cursor: default;
  }

  .outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
  }

  .success,
  .error,
  .loader {
    display: none;
    font-size: 13px;
  }

  .success.visible,
  .error.visible {
    display: inline;
  }

  .loader.visible {
    display: block;
  }

  .error {
    color: #e4584c;
  }

  .success {
    color: #666ee8;
  }

  .success .token {
    font-weight: 500;
    font-size: 13px;
  }

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666ee8; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    margin: 8px;
  }

  .loader-small {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666ee8; /* Blue */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .input-box {
    margin-left: 12px;
    margin-right: 6px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
`;

const FormWrapper = styled.form`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  .title {
    font-size: 2em;
    text-align: center;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 1em auto;
    max-width: 1300px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: 0.5em;
  }

  .table {
    display: block;
    max-width: 1300px;
    margin: 0.5em auto;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  .tr td {
    margin: 0;
    border: none;
  }

  .table tbody td {
    border-bottom: 1px solid #ccc;
  }

  .table th {
    padding: 0.4em;
  }

  .table tbody td {
    padding: 0.4em;
  }

  .table td:first-child {
    max-width: 50px;
    text-align: center;
  }

  .table td:nth-child(2) {
    max-width: 250px;
    padding-left: 1em;
  }

  .table td:nth-child(3) {
    width: 50%;
    padding-left: 1em;
  }

  .table input {
    border: 1px solid #aaa;
  }

  input[disabled] {
    background-color: #ffe;
  }

  .radios > div {
    margin-bottom: 0.5em;
  }

  .radios input {
    margin-right: 0.5em;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .buttons button {
    margin: 1em;
  }

  .pdf {
    height: 80vh;
  }

  .quoteModal {
    font-size: 1.4em;
    max-width: 400px;
    text-align: center;
    margin: auto;
  }
  .quoteModal p {
    margin-bottom: 1em;
  }

  .quoteModal button {
    display: block;
    margin: 1em auto;
  }

  .payment {
    margin: 2em auto;
    width: 100%;
  }

  .paymentTitle {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 0.5em;
  }

  .paymentDisclaimer {
    font-weight: bold;
    font-size: 1.1em;
  }

  .monthInput {
    width: 42px;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 0.5em 0.5em;
    font-size: 91%;
  }

  .expDivider {
    margin: 0 0.5em;
  }

  .yearInput {
    width: 60px;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 0.5em 0.5em;
    font-size: 91%;
  }

  .yearHolder {
    display: inline-block;
    padding: 0 8px 0 0;
  }

  .monthHolder {
    display: inline-block;
  }

  .cvvInput {
    width: 50px;
    height: 35px;
    display: inline-block;
    margin: 3px;
  }

  .cardInput {
    width: 180px;
    height: 35px;
    display: inline-block;
    margin: 3px;
  }

  .instructionsButton {
    margin-bottom: 1em;
  }

  .trackEditTextarea {
    width: 100%;
    height: 65px;
    min-height: 65px;
    padding: 4px;
    resize: vertical;
    font-size: 1em;
  }
`;

export default class UserEditing extends React.Component {
  constructor(props) {
    console.log(props.booking);
    super(props);
    const state = localStorage.getItem(`${props.booking.id}_editing_draft`);
    this.state = {
      form: state ? JSON.parse(state) : { tracks: {} },
      show_instructions: false,
      show_quote: false
    };
  }

  getTracks = async () => {
    http()
      .get(`/tracks/management-info?bookingId=${this.props.booking.id}`)
      .then(response => {
        if (response.success) {
          const main = response.result.mainTracks.map(track => {
            const cues = track.cue_number.split('-');
            track.cue_start = cues[0] ? cues[0] : track.cue_number;
            track.cue_end = cues[1] ? cues[1] : track.cue_number;
            return track;
          });

          this.setState({ main_tracks: main });
        }
      });
  };

  getBookingEdits = async () => {
    http()
      .get(`/editing/list?booking_id=${this.props.booking.id}`)
      .then(response => {
        if (response.success) {
          this.setState({ edits: response.items });
        }
      });
  };

  getOrg = async () => {
    const REQ_OBJECT = `{
      organization {
        id
        name
        account_number
      }
    }`;

    const res = await getMe(REQ_OBJECT);
    const org = res.data.getMe.organization;
    this.setState({ org });
  };

  onSubmit = async e => {
    console.log('tar', e.target?.value);
    console.log('curr', e.currentTarget?.value);
    console.log(this.state.form);

    const staxPayload = {
      firstname: document.querySelector('input[name="cardholder-first-name"]')
        .value,
      lastname: document.querySelector('input[name="cardholder-last-name"]')
        .value,
      phone: document.querySelector('input[name="cardholder-phone"]').value,
      address_1: document.querySelector('input[name="cardholder-address-1"]')
        .value,
      address_2: document.querySelector('input[name="cardholder-address-2"]')
        .value,
      address_city: document.querySelector('input[name="cardholder-city"]')
        .value,
      address_state: document.querySelector('input[name="cardholder-state"]')
        .value,
      address_zip: document.querySelector('input[name="cardholder-zip"]').value,
      month: document.querySelector('input[name="card-month"]').value,
      year: document.querySelector('input[name="card-year"]').value,
      method: 'card'
    };

    let staxResponse;
    try {
      staxResponse = await this.state.stax.tokenize(staxPayload);
    } catch (e) {
      let message = e.message;
      if (message) {
        return toast.error('Error processing card, ' + message);
      }
      try {
        message = e[Object.keys(e)[0]][0];
        return toast.error('Error processing card, ' + message);
      } catch (e) {
        return toast.error('Error processing card, Something went wrong');
      }
    }

    if (!staxResponse.has_cvv) {
      return toast.error('Error processing card, CVV is required');
    }

    const payload = this.state.form;
    payload.booking_id = this.props.booking.id;
    payload.card_token = staxResponse.id;
    payload.card_nickname = staxResponse.nickname;

    // Make sure at least 1 edit is requested
    let hasOneEdit = false;
    for (const track of Object.values(this.state.form.tracks)) {
      if (track && String(track).trim().length > 0) {
        hasOneEdit = true;
        break;
      }
    }

    if (!hasOneEdit) {
      toast.error('At least one edit is required to submit this form');
      return;
    }

    http()
      .post('/editing/submit', this.state.form)
      .then(response => {
        console.log(response);
        if (response.success) {
          toast.success('Editing sheet submitted successfully');
          localStorage.removeItem(`${this.props.booking.id}_editing_draft`);
          window.location.href = `/org/edits/${response.id}`;
          return;
        }
        toast.error(
          response.message || 'Something went wrong, please try again later'
        );
      });
  };

  onSave = () => {
    const key = `${this.props.booking.id}_editing_draft`;
    localStorage.setItem(key, JSON.stringify(this.state.form));
    toast.success('Draft saved, you can continue later.');
  };

  handleTrackChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        tracks: {
          ...this.state.form.tracks,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: value
      }
    });
  };

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const stax = new StaxJs(process.env.REACT_APP_STAX_API_KEY, {
      number: {
        id: 'stax_card',
        placeholder: '0000 0000 0000 0000',
        style:
          'width: 90%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
      },
      cvv: {
        id: 'stax_cvv',
        placeholder: 'CVV',
        style:
          'width: 30px; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
      }
    });

    setTimeout(() => {
      stax.showCardForm();
    }, 4000);
    this.setState({ stax });
  }

  UNSAFE_componentWillMount() {
    this.getTracks();
    this.getOrg();
    this.getBookingEdits();
  }

  render() {
    if (!this.state.main_tracks || !this.state.org || !this.state.edits) {
      return null;
    }

    const active = this.state.edits?.find(edit =>
      pendingStatuses.includes(edit.status)
    );
    if (active) return <UserEditRequest id={active.id} hideHeader />;

    const { booking } = this.props;

    return (
      <FormWrapper onSubmit={e => e.preventDefault()}>
        <div className="header">
          <div className="title">{booking.show.name} Edit Sheet</div>
        </div>

        <div className="topInfo">
          <div className="topInfo1">
            <div>Please fill out the contact information below:</div>
            <div>
              <div className="field">
                Name:{' '}
                <Input
                  required
                  value={this.state.form.name}
                  name="name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                Phone:{' '}
                <Input
                  required
                  type="phone"
                  value={this.state.form.phone}
                  name="phone"
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                Email:{' '}
                <Input
                  type="email"
                  required
                  value={this.state.form.email}
                  name="email"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="topInfo2">
            <div>Organization Information</div>
            <div>
              <div className="field">
                Organization: <Input value={this.state.org.name} disabled />
              </div>
              <div className="field">
                Opening Date: <Input value={booking.opening} disabled />
              </div>
              <div className="field">
                Account Number:{' '}
                <Input value={this.state.org.account_number} disabled />
              </div>
            </div>
          </div>
          <div className="topInfo3">
            <div>Select turnaround time</div>
            <div className="radios">
              <div>
                <input
                  type="radio"
                  name="turnaround"
                  required
                  value="5"
                  checked={this.state.form.turnaround === '5'}
                  onChange={this.handleChange}
                />
                5 Business-day ($60/hour)
              </div>
              <div>
                <input
                  type="radio"
                  name="turnaround"
                  required
                  value="2"
                  checked={this.state.form.turnaround === '2'}
                  onChange={this.handleChange}
                />
                2 Business-day ($120/hour)
              </div>
            </div>
          </div>
          <div className="topInfo4">
            <div>Using MTPit Pro Application?</div>
            <div className="radios">
              <div>
                <input
                  required
                  type="radio"
                  name="using_pro"
                  value="yes"
                  checked={this.state.form.using_pro === 'yes'}
                  onChange={this.handleChange}
                />
                Yes
              </div>
              <div>
                <input
                  required
                  type="radio"
                  name="using_pro"
                  value="no"
                  checked={this.state.form.using_pro === 'no'}
                  onChange={this.handleChange}
                />
                No
              </div>
            </div>
          </div>
        </div>

        <div className="instructionsButton">
          <Button
            type="button"
            onClick={() => this.setState({ show_instructions: true })}
          >
            Instructions
          </Button>
        </div>

        <div className="table">
          <div>Master Tracks</div>
          <table>
            <thead>
              <tr>
                <th>Cue #</th>
                <th>Name</th>
                <th>Edits</th>
              </tr>
            </thead>
            <tbody>
              {this.state.main_tracks.map(track => {
                return (
                  <tr key={track.id}>
                    <td>{track.cue_number}</td>
                    <td>{track.track_name}</td>
                    <td>
                      <textarea
                        className="trackEditTextarea"
                        value={this.state.form.tracks[track.id] || ''}
                        name={track.id}
                        onChange={this.handleTrackChange}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Payment className="payment">
            <div className="paymentTitle">Payment details</div>

            <div className="group">
              <label>
                <span>First Name</span>
                <input
                  name="cardholder-first-name"
                  className="field input-box"
                  placeholder="Jane"
                />
              </label>
              <label>
                <span>Last Name</span>
                <input
                  name="cardholder-last-name"
                  className="field input-box"
                  placeholder="Doe"
                />
              </label>
              <label>
                <span>Phone</span>
                <input
                  name="cardholder-phone"
                  className="field input-box"
                  placeholder="+1000000000000"
                />
              </label>
            </div>
            <div className="group">
              <label>
                <span>Address Line 1</span>
                <input
                  name="cardholder-address-1"
                  className="field input-box"
                  placeholder=""
                />
              </label>
              <label>
                <span>Address Line 2</span>
                <input
                  name="cardholder-address-2"
                  className="field input-box"
                  placeholder=""
                />
              </label>
              <label>
                <span>City</span>
                <input
                  name="cardholder-city"
                  className="field input-box"
                  placeholder=""
                />
              </label>
              <label>
                <span>State</span>
                <input
                  name="cardholder-state"
                  className="field input-box"
                  placeholder=""
                />
              </label>
              <label>
                <span>Zip Code</span>
                <input
                  name="cardholder-zip"
                  className="field input-box"
                  placeholder=""
                />
              </label>
            </div>

            <div className="group">
              <label>
                <span>Card</span>
                <div id="card-element" className="field">
                  <div id="stax_card" className="cardInput"></div>
                  <div id="stax_cvv" className="cvvInput"></div>
                </div>
                <div className="monthHolder">
                  <input
                    name="card-month"
                    className="monthInput"
                    size="3"
                    maxLength="2"
                    placeholder="MM"
                  />
                </div>
                <div className="expDivider">/</div>
                <div className="yearHolder">
                  <input
                    name="card-year"
                    className="yearInput"
                    size="5"
                    maxLength="4"
                    placeholder="YYYY"
                  />
                </div>
              </label>
            </div>

            <div className="paymentDisclaimer">
              Your card will not be charged at this time, it will be stored on
              file and will be charged based on the turnaround time and the
              hours taken to complete the edits, the charge will occur upon
              completion.
            </div>
          </Payment>

          <div className="buttons">
            <Button value="submit" onClick={this.onSubmit}>
              Submit
            </Button>
            <Button type="button" value="save" onClick={this.onSave}>
              Save Draft
            </Button>
            <Button
              type="button"
              value="quote"
              onClick={() => this.setState({ show_quote: true })}
            >
              Need a Quote
            </Button>
          </div>
        </div>

        <Modal
          open={this.state.show_quote}
          onClose={() => this.setState({ show_quote: false })}
          closeOnOverlay={true}
        >
          <div className="quoteModal">
            <p>
              Typically 8-10 edits can be done in an hour. If using the Pro App
              edits will take longer.
            </p>
            <p>
              If you want a more detailed quote reach out to The MT Pit via
              phone at
              <br />
              <a href="tel://8018839933">801-883-9933</a>
            </p>
            <Button
              type="button"
              onClick={() => this.setState({ show_quote: false })}
            >
              Understood
            </Button>
          </div>
        </Modal>

        <Modal
          open={this.state.show_instructions}
          onClose={() => this.setState({ show_instructions: false })}
          closeOnOverlay={true}
        >
          <div className="pdf">
            <object
              type="application/pdf"
              data="/instructions.pdf"
              width="100%"
              height="100%"
            >
              <p>
                Failed to display instruction, to download them{' '}
                <a href="/instructions.pdf">press here</a>
              </p>
            </object>
          </div>
        </Modal>
      </FormWrapper>
    );
  }
}
