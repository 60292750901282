import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, FormWrapper, Text, Input, Button } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 1em auto;
    max-width: 1300px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: .5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: .5em;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #CCC;
  }

  .table th {
    padding: .4em;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    max-width: 50px;
    text-align: center;
  }

  .table td:nth-child(2) {
    max-width: 250px;
    padding-left: 1em;
  }

  .table input {
    border: 1px solid #AAA;
  }

  input[disabled] {
    background-color: #FFE;
  }

  .radios > div {
    margin-bottom: .5em;
  }

  .radios input {
    margin-right: .5em; 
  }

  .updateButton {
    margin-bottom: 2em;
  }

  .paymentNickname {
    font-size: 1.2em;
    font-weight: bold;
    margin: .5em auto;
    background: #EFEFEF;
    display: block;
    padding: 6px;
    border-radius: 10px;
    max-width: 450px;
    margin-bottom: 2em;
  }

  .hoursForm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .hoursForm .hoursInput {
    width: 80px;
    padding: 0 10px;
    margin-right: 30px;
  }

  .hoursForm input {
    width: 100%;
    height: 30px;
  }

  .hoursForm input {
    background: #EFEFEF;
  }

  .chargeTitle {
    margin: 2em auto 1em;
  }
`

const rates = {
  '5': 60,
  '2': 120,
}

export default class OrgEdits extends React.Component {
  constructor(props) {
    console.log('PROPS ', props)
    super();
    this.state = {
      show: {},
      edit: {},
      booking: {},
      update: {},
      hours: 1,
    };
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      update: {
        ...this.state.update,
        [e.target.name]: value
      }
    });
  };

  updateClient = async () => {
    if (this.state.loading) return
    if (!confirm('Are you sure you want to send an email to the client?')) {
      return
    }
    this.setState({ loading: true });
    await http().post(`/editing/notify`, { id: this.state.edit.id })
      .then(response => {
        if (response.success) return toast.success('Successfully sent email');
        toast.error('Error sending email, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error sending email, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  updateTop = async () => {
    this.setState({ loading: true });
    await http().post(`/editing/update`, {
      id: this.state.edit.id,
      status: this.state.update.status,
      assigned_to: this.state.update.assigned_to,
    })
      .then(response => {
        if (response.success) return toast.success('Successfully updated edit');
        toast.error('Error updating, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error updating, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  triggerCharge = () => {
    const checkboxes = document.querySelectorAll('#editRows [type="checkbox"]');
    for(let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) {
        return toast.error('You must check all edits before charging');
      }
    }

    toast.success(`WIP - charging for ${this.state.hours} hours`);
  }

  async componentDidMount() {
    await http().get(`/editing/${this.props.match.params.id}`).then(response => {
      if (!response.success) {
        return toast.error('Failed to retrieve edit');
      }
      console.log(response)
      this.setState({ ...response, update: response.edit });
    }).catch(error => {
      console.log(error)
      toast.error(error.message);
    })
  }

  render() {
    if (!this.state.edit || !this.state.edit.edits || !this.state.booking) return null;
    return <Wrapper>
      <Header>
        <Header.Head bold huge>
          Track edit requests for {this.state.show.name}
        </Header.Head>
      </Header>
      <FormWrapper>
        <div className='topInfo'>
          <div className='topInfo1'>
            <div>Contact information</div>
            <div>
              <div className="field">
                Name: <Input value={this.state.edit.name} />
              </div>
              <div className="field">
                Phone: <Input value={this.state.edit.phone} />
              </div>
              <div className="field">
                Email: <Input type="email" value={this.state.edit.email} />
              </div>
            </div>
          </div>
          <div className='topInfo2'>
            <div>Organization Information</div>
            <div>
              <div className="field">
                Organization: <Input value={this.state.org.name} disabled />
              </div>
              <div className="field">
                Opening Date: <Input value={this.state.booking.opening} disabled />
              </div>
              <div className="field">
                Account Number: <Input value={this.state.org.account_number} disabled />
              </div>
            </div>
          </div>
          <div className='topInfo3'>
            <div>Turnaround time</div>
            {this.state.edit.turnaround_time === '5' && (
              <div> 5 Business-day ($60/hour) </div>
            )}

            {this.state.edit.turnaround_time === '2' && (
              <div> 2 Business-day ($120/hour) </div>
            )}
          </div>
          <div className='topInfo4'>
            <div>Using MTPit Pro Application?</div>
            <div>
              {this.state.edit.using_pro ? 'Yes' : 'No'}
            </div>
          </div>
          <div className='topInfo4'>
            <div>Status</div>
            <div>
              <select onChange={this.handleChange} name="status" value={this.state.update.status}>
                <option checked={this.state.update.status === 'submitted'} value="submitted">Submitted</option>
                <option checked={this.state.update.status === 'pending'} value="pending">In Progress</option>
                <option checked={this.state.update.status === 'complete'} value="complete">Complete</option>
                <option checked={this.state.update.status === 'cancelled'} value="cancelled">Cancelled</option>
              </select>
            </div>
          </div>
          <div className='topInfo4'>
            <div>Assigned to</div>
            <div>
              <Input name="assigned_to" onChange={this.handleChange} value={this.state.update.assigned_to} />
            </div>
          </div>
        </div>

        <div className="updateButton" disabled={this.state.loading}>
          <Button onClick={this.updateTop}>Update status and assignee</Button>
        </div>

        <div className="updateButton" disabled={this.state.loading}>
          <Button onClick={this.updateClient}>Send status update email</Button>
        </div>

        <Text large>Edits requested</Text>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Cue #</th>
                <th>Track Name</th>
                <th colSpan="2">Edit Request</th>
              </tr>
            </thead>
            <tbody id="editRows">
              {this.state.edit.edits.map(edit => {
                return <tr key={edit.id}>
                  <td>{edit.cue_number}</td>
                  <td>{edit.track_name}</td>
                  <td>{edit.edit}</td>
                  <td><input type="checkbox"/></td>
                </tr>
              })}
            </tbody>
          </table>
        </div>

        <Text large>Payment method</Text>
        <div className="paymentNickname">
          {this.state.edit.card_nickname}
        </div>

        <Text large className="chargeTitle">Charge customer</Text>
        <div className="hoursForm">
          Hours:
          <Input className="hoursInput" type="number"
            value={this.state.hours}
            onChange={e => this.setState({ hours: e.target.value >= 0 ? e.target.value : 0 })} />
        </div>
        <Button onClick={this.triggerCharge}>
          Charge{' '}
          {this.state.hours && !isNaN(this.state.hours) ? `$${(this.state.hours * rates[this.state.edit.turnaround_time || '2']).toFixed(2)}` : '$0.00'}
        </Button>
      </FormWrapper>
    </Wrapper>;
  }
}

